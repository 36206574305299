import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  font-size: 0;
  line-height: 1;
  max-width: 776px;
  margin: 0 auto;
`

export const work = [
  {
    id: "reel",
    video: "https://player.vimeo.com/video/1049871047?autoplay=1",
    thumb: "reel-thumb-x2.jpg",
    url: "/work/reel",
  },
  // {
  //   id: "editing-reel",
  //   video: "https://player.vimeo.com/video/673340827?h=f363b148db&autoplay=1",
  //   thumb: "editing-reel.png",
  //   url: "/work/editing-reel",
  // },
  // {
  //   id: "reel",
  //   video: "https://player.vimeo.com/video/899051401?autoplay=1",
  //   thumb: "reel.jpg",
  //   url: "/work/reel",
  // },
  // {
  //   id: "animation-reel",
  //   video: "https://player.vimeo.com/video/673659812?h=18e38ddffe&autoplay=1",
  //   thumb: "animation-reel.png",
  //   url: "/work/animation-reel",
  // },
  // {
  //   id: "podcast-editing",
  //   thumb: "podcast-editing.jpg",
  //   url: "https://bit.ly/3oqki7Y",
  // },
]

export default function Portfolio() {
  return (
    <Grid>
      {work.map(video => {
        return (
          <Link to={video.url} key={video.thumb}>
            <img src={`/thumb/${video.thumb}`} />
          </Link>
        )
      })}
    </Grid>
  )
}
